// inline scss
import '../scss/app.scss';

// jQuery
import $ from 'jquery';
window.$ = $;

// Outdated browsers
import browserUpdate from '../node_modules/browser-update/update.npm.full.js';

// Bootstrap
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import '../node_modules/slick-carousel/slick/slick.js';

// Tablesaw
import '../node_modules/tablesaw/dist/tablesaw.js';
import '../node_modules/tablesaw/dist/tablesaw.jquery.js';
import '../node_modules/tablesaw/dist/tablesaw-init.js';

//Custom
import browserUpdateParameters from './browserUpdate.js';
import { smoothScrool, goTop } from './scrool.js';

//lazy loading
import './lazy-loading/intersection-observer-polyfill.js';
import './lazy-loading/intersection-observer-script.js';

import '../node_modules/magnific-popup/dist/jquery.magnific-popup.js';
import '../node_modules/magnific-popup/src/css/main.scss';

function MoreMenu() {
    const mq = window.matchMedia("(max-width: 768px)");
    if ($('.accordion').length && mq.matches) {
        $('.accordion').off('click').on('click', function () {
            $(this).next('.panel').slideToggle();
        });
    } else {
        $('.accordion').next('.panel').show();
    }
}
$(document).ready(function () {
    browserUpdate(browserUpdateParameters);
    goTop();
    smoothScrool();
    $(function () {
        $('ul.navbar-nav li a[href="' + location.pathname.split("/")[location.pathname.split("/").length - 1] + '"]').children().addClass('active');
    });
    $('.slider-photos').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4500,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 5500,
                    centerMode: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    $('#goToCountry,#goToCountryDesktop').change(function () {
        console.log('change');
        if ($(this).val() !== '#') {
            var navBarH = $($('.navbar').is(':visible') ? '.navbar' : '.navbar-mobile').outerHeight();
            $('html, body').animate({
                scrollTop: $('#' + $(this).val()).offset().top - navBarH
            }, 500);
        }
    });
    $(document).click(function (event) {
        var clickover = $(event.target);
        var _opened = $(".navbar-collapse").hasClass("show");
        if (_opened === true && clickover.parents(".wrapper").length > 0) {
            $("button.navbar-toggler").click();
        }
    });



    $(window).resize(function () {
        MoreMenu();
    });
    MoreMenu();

    if ($('.gallery_container').length) {
        $('.gallery_container').magnificPopup({
            delegate: 'a', // child items selector, by clicking on it popup will open
            type: 'image',
            gallery: { enabled: true }
            // other options
        });
    }


    class SliderReveal {
        constructor() {
            this.revealButtons = document.querySelectorAll('.reveal');
            this.closeButtons = document.querySelectorAll('.overlay_close');
            this.containers = document.querySelectorAll('.fullpage_overlay');
            this.init();
        }

        init() {
            this.revealButtons.forEach(button => {
                button.addEventListener('click', (e) => this.handleReveal(e));
            });

            this.closeButtons.forEach(button => {
                button.addEventListener('click', (e) => this.handleReveal(e));
            });

            this.containers.forEach(container => {
                container.addEventListener('click', (e) => {
                    console.log('click on container');
                    if (e.target === container) {
                        this.handleReveal(e);
                    }
                });
            });
        }

        handleReveal(event) {
            const targetSelector = event.currentTarget.getAttribute('data-target');
            const target = document.querySelector(targetSelector);

            if (target.classList.contains('d-none')) {
                this.slideUp(target);
            } else {
                this.slideDown(target);
            }

        }
        slideDown(element) {
            setTimeout(() => {
                element.classList.add('d-none');
            }, 10);
        }

        slideUp(element) {
            setTimeout(() => {
                element.classList.remove('d-none');
            }, 500); // Match transition duration
        }
    }

    // Instantiate the SliderReveal class when the page loads
    window.onload = () => {
        new SliderReveal();
    };
});

